import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
//import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Hakkımızda</Subheading>}
        heading="İşletmeniz için yenilikçi çözümler sunuyoruz"
        description="İşletmeniz için yenilikçi çözümler sunuyoruz. Müşteri yorumlarını kolayca toplayıp analiz etmenizi sağlıyor ve modern teknolojilerle yapay zeka destekli yanıtlarla müşteri memnuniyetini artırıyoruz. İşletmenizin ihtiyaçları için özelleştirilmiş çözümlerle başarınıza güç katıyoruz."
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Vizyonumuz</Subheading>}
        heading="Müşteri Deneyimlerini Bir Üst Seviyeye Taşıyoruz"
        description="İşletmenizin ihtiyaçlarını analiz ediyor, müşteri memnuniyetini artırmak ve büyümeyi hızlandırmak için yenilikçi teknolojiler sunuyoruz. Güçlü vizyonumuz ve etkili çözümlerimizle sizi daima bir adım öne taşıyoruz."
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />

      <Features
        subheading={<Subheading>Değerlerimiz</Subheading>}
        heading="Başarıya ulaşmanın temel taşları"
        description="Müşterilerimiz için en iyi çözümleri aşağıdaki değerlerimizle sağlıyoruz:"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Sürekli Destek",
            description: "Her zaman yanınızdayız. Sorularınızı çözmek ve işletmenizi desteklemek için buradayız.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Güçlü Teknoloji",
            description: "İşletmenizi büyütmek için en yeni teknolojileri kullanıyoruz.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Müşteri Odaklılık",
            description: "Müşterilerimizin ihtiyaçlarına göre özelleştirilmiş hizmetler sunuyoruz.",
          },
        ]}
        linkText=""
      />
    <Footer />
    </AnimationRevealPage>
  );
};
      /*
      <TeamCardGrid 
        subheading={<Subheading>Ekibimiz</Subheading>}
        description="Alanında uzman, yenilikçi ve yaratıcı ekibimiz, işletmenizi büyütmek için her zaman yanınızda. Müşteri memnuniyetini ön planda tutarak size en iyi çözümleri sunmayı hedefliyoruz."
        heading="Uzman kadromuzla tanışın."
      />
      <Footer />
      */