import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
//import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
//import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
//import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(({ textOnLeft }) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(({ imageRounded, imageBorder, imageShadow }) => [
  imageRounded && tw`rounded`,
  imageBorder && tw`border`,
  imageShadow && tw`shadow`,
]);
/*
const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);
*/
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
/*
const PrimaryButton = styled(PrimaryButtonBase)(({ buttonRounded }) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  buttonRounded && tw`rounded-full`
]);
*/
export default ({
  subheading = "Uzmanlık Alanımız",
  heading = (
    <>
      Profesyoneller <span tw="text-primary-500">tarafından tasarlandı.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonAction = () => {}, // Varsayılan bir boş işlev eklendi
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  textOnLeft = true
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
