import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

// Yeni tasarımda tek kart olduğunda dikey ve yatay ortalama sağlanıyor.
const StyledContactDetails = tw.div`
  flex flex-col items-center justify-center h-full w-full
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
       <StyledContactDetails>
        <ContactDetails
          cards={[
            {
              title: "İstanbul",
              description: (
                <>
                  <Address>
                    {/* <AddressLine>İĞRİP SK. NO: 13 İÇ KAPI NO: 1</AddressLine> */}
                    <AddressLine>FENERBAHÇE MAH.</AddressLine>
                    <AddressLine>KADIKÖY</AddressLine>
                  </Address>
                  <Email>info@feedup.ai</Email>
                  <Phone>+90 507 487 7035</Phone>
                </>
              )
            }
          ]}
        />
      </StyledContactDetails>
      <Footer />
    </AnimationRevealPage>
  );
};
