import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
//import reviewImage from "images/Respond-to-Google-Reviews.png";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-contain bg-center rounded-t-lg`}
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Sistem Özellikleri",
  posts = [
    {
      imageSrc:
        "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*H8_qskGMer4C5bP6d9OvNA.png",
      category: "İşletme Kartları",
      title: "Kart Ayarları ve Bilgileri",
      description:
        "İşletme kartlarınızın detaylarını görüntüleyebilir, kartlarınızı isimlendirebilir, istediğiniz tarih aralıklarında kart okutma sayılarına ücretsiz ulaşabilirsiniz.",
      featured: true
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1791484127/vector/procedure-of-evacuation-emergency-map-tiny-people-notice-about-safe-exit-from-building.jpg?s=612x612&w=0&k=20&c=JiUcqdgSQd_u4a4hhEBxSKUMT5rJXfI2Xcm9Q6lB81A=",
      category: "Acil Önlemler",
      title: "İşletme Puanı ve Öneriler",
      description:
        "İşletmenizin müşteri puanlarını detaylı bir şekilde görüntüleyebilir, müşteri memnuniyetini artırmak için sistemin sağladığı otomatik iyileştirme önerilerini takip edebilirsiniz.",
      featured: true 
    },
    {
      imageSrc:
        "/feedup_nfc2.png",
      category: "Raporlar",
      title: "Haftalık ve Aylık Raporlar",
      description:
        "İşletmenizin haftalık ve aylık performansını analiz eden raporlarla gelir, müşteri memnuniyeti ve yorum trendlerini kolayca takip edin. Bu raporlar, gelişim için temel içgörüler sunar.",
      featured: true
    },
    {
      imageSrc:
        "/ext.jpg",
      category: "Cevap Üretimi",
      title: "Otomatik Yanıtlar",
      description:
        "Müşteri yorumlarına yapay zeka destekli otomatik yanıtlar oluşturun. Yorumları anında analiz ederek profesyonel, samimi veya resmi tarzda yanıtlar üretebilirsiniz.",
      featured: true
    },
    {
      imageSrc:
        "https://img.freepik.com/premium-vector/review-icon-like-thin-line-loupe-paper-concept-market-data-statistics-research-business-forecast-flat-stroke-seek-logotype-graphic-lineart-black-design-art-isolated-white-background_995545-249.jpg",
      category: "Analiz",
      title: "Yorum Analizi",
      description:
        "Müşteri yorumlarını kategori bazında detaylı bir şekilde analiz edin. Geri bildirimleri olumlu ve olumsuz olarak sınıflandırarak işletmenizin güçlü ve zayıf yönlerini belirleyin.",
      featured: true  
    }
  ]
}) => {
  const [visible, setVisible] = useState(6);
  const onLoadMoreClick = () => {
    setVisible(v => v + 3);
  };

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url || "#"}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Daha Fazla Göster</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
