import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SingleCol.js";
import TabGrid from "components/cards/TabCardGrid.js";
import tw from "twin.macro";


const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Pricing />
      <TabGrid
        heading={
          <>
            Öne Çıkan <HighlightedText>Hizmetlerimiz.</HighlightedText>
          </>
        }
      />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
