import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
//import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
/*
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;*/
const TextContainer = styled.div`
  ${tw`mt-8 text-sm sm:text-base text-gray-700 leading-relaxed`}
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  white-space: pre-wrap; /* Text'i olduğu gibi göstermek için */
`;


const defaultContent = `
MESAFELİ SATIŞ SÖZLEŞMESİ
Madde 1 – Taraflar:
1.1. Satıcı
Unvanı	: 
Adresi	:
Telefon	:
Faks	:
Email	: 
Vergi Dairesi	: 
Vergi No	: 
Mersis No	: 
1.2. Alıcı
Adı / Unvanı	:
(TC. No/Mersis No)	:
Adresi	:
Telefon	:
E-mail	:
Vergi Dairesi	:
Vergi No	:
Not : Vergi Mükellefi değilseniz vergi dairesi ve numarasını boş bırakınız.
Bundan sonra Alıcı ve Satıcı ayrı ayrı “Taraf”, birlikte “Taraflar” olarak anılacaktır.
Madde 2 – Konu:
İşbu Mesafeli Satış Sözleşme’nin (“Sözleşme”) konusu, Alıcı’nın Satıcı’ya ait ……… internet sitesinden elektronik ortamda siparişini gerçekleştirdiği; aşağıda nitelikleri ve satış ücreti belirtilen, ilgi web sitesinde nitelikleri sunulan elektronik kartların (“Ürün”) Alıcı tarafından talep edilen vasıf nitelik ve nicelikte satışı ve teslimi ile ilgili olarak, tacir olan Alıcılar için 6102 sayılı Türk Ticaret Kanunu ve tüketici olan Alıcılar için 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
Alıcı kendisinin; Satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri, satışa konu Ürünün temel nitelikleri, vergiler dahil olmak üzere satış fiyatı, ödeme sekli, teslimat koşulları ve masrafları dahil satışa konu Ürün ile ilgili tüm ön bilgiler, "cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve itirazlarını iletebilecekleri resmi makamlar dahil ilgili kurumlar hususlarında açık, anlaşılır ve internet ortamına uygun şekilde Satıcı tarafından bilgilendirildiğini, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında mal sipariş verdiğini işbu Sözleşme hükümlerince kabul, beyan ve taahhüt eder.
…………………………. internet sitesinde yer alan ön bilgilendirme ve Alıcı tarafından verilen sipariş üzerine düzenlenen fatura işbu Sözleşmenin ayrılmaz parçalarıdır.

Madde 3 – Sözleşme Konusu Ürün/Ödeme/Teslimat Bilgileri
Elektronik ortamda alınan Ürünün cinsi ve türü, miktarı, ağırlığı, marka/modeli, satış bedeli, ödeme şekli, teslim alacak kişi, teslimat adresi, fatura bilgileri, kargo ücreti aşağıda belirtildiği gibidir. Fatura edilecek kişi (özel veya tüzel) ile Sözleşmeyi yapan Alıcının bilgileri ile aynı olmak zorundadır. Aşağıda yer alan bilgiler doğru ve eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu durumlardan doğacak zararları tamamıyla karşılamayı ve ayrıca bu durumdan oluşabilecek her türlü sorumluluğu Alıcı kabul, beyan ve taahhüt eder.
Satıcı gerekli gördüğü durumlarda, Alıcının vermiş olduğu bilgiler gerçekle örtüşmediğinde, siparişi durdurma hakkını saklı tutar. Satıcı siparişte sorun tespit ettiği durumlarda Alıcının vermiş olduğu telefon, e-posta ve posta adreslerinden Alıcıya ulaşamadığı takdirde siparişin yürürlüğe koyulmasını 15 (onbeş) gün süreyle dondurur. Alıcının bu süre zarfında Satıcı ile konuyla ilgili olarak iletişime geçmesi beklenir. Bu süre içerisinde Alıcıdan herhangi bir cevap alınamazsa Satıcı, her iki tarafın da zarar görmemesi için siparişi iptal eder.
Alınan Ürün
Adı, kodu		:
Adet			:
Toplam Satış Bedeli	: …. -USD (KDV DAHİL /HARİÇ)
Ödeme Şekli		: Kredi Kartı/Banka Havalesi (EFT/Havale)
Teslim Edilecek Kişi	:
Telefon numarası	:
Teslim Edilecek Adres:
Fatura Edilecek Kişi	:
Fatura Adresi		:
Vergi Dairesi		:
Vergi No		:
Kargo Ücreti		:
Madde 4 – Sözleşme Tarihi 
Sözleşme tarihi, Alıcı tarafından siparişin verildiği tarih olan .../../….tarihidir.
Madde 5 – Mücbir Nedenler
Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülmeyen, Tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla Taraflardan birinin ya da her ikisinin de Sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep (doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde arıza vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal ve yazılı olarak bildirecektir.
Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 30 (otuz) gün süreyle devam ederse, taraflardan her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
Madde 6- Satıcının Hak ve Yükümlülükleri
6.1. Satıcı, Alıcıya karşı Alıcı tacir ise 6102 sayılı Türk Ticaret Kanunu, Alıcı tüketici ise 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri uyarınca Sözleşmede kendisine yüklenen edimleri mücbir haller dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder. Tacir olan Alıcılar yönünden; 6102 sayılı Türk Ticaret Kanunu ve teamülden ayrı olarak tacir olan Alıcının mesafeli satış sözleşmesinden ve 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ile alt mevzuatından doğan yasal hakkı var ise bu yasal hakları saklıdır. 
6.2.18 (on sekiz) yaşından küçük kişiler ……………………. adresinden alışveriş yapamaz. Satıcı, Alıcının Sözleşmede belirttiği yaşının doğru olduğunu esas alacaktır. Ancak Alıcının yaşını yanlış yazmasından dolayı Satıcıya hiçbir şekilde sorumluluk yüklenemeyecektir.
6.3.Sistem hatalarından meydana gelen fiyat yanlışlıklarından ……………………. sorumlu değildir. Buna istinaden Satıcı, internet sitesindeki sistemden, dizayndan veya yasadışı yollarla internet sitesine yapılabilecek müdahaleler sebebiyle ortaya çıkabilecek tanıtım, fiyat hatalarından sorumlu değildir. Sistem hatalarına dayalı olarak Alıcı Satıcıdan hak iddiasında bulunamaz.
6.4. …………………….  adresinden kredi kartı (Visa, MasterCard , vs. ) ya da banka havalesi ile alışveriş yapılabilir. Sipariş tarihinden itibaren bir hafta içinde havalesi yapılmayan siparişler iptal edilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’nin) banka hesaplarına ulaştığı belirlenen andır. Ödemeli gönderi ya da posta çeki gibi müşteri hizmetleri ile görüşülmeden gerçekleştirilen ödeme yöntemleri kabul edilmez.
Madde 7 – Alıcının Hak ve Yükümlülükleri
7.1. Alıcı, Sözleşmede kendisine yüklenen edimleri mücbir sebepler dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.
7.2. Alıcı tacir ise 6102 sayılı Türk Ticaret Kanunu ve bağlı alt mevzuata tabi olduğunu, Alıcı tüketici ise 6502 sayılı Yasa ve alt mevzuata tabi olduğunu kabul, beyan ve taahhüt eder. Alıcının şahıs olması tüketici olduğu anlamına gelmez. Alıcının kimin adına fatura ettirdiği, fatura ettirilen muhatabın kimliği bu konuda esastır.
7.3.Alıcı, sipariş vermekle birlikte iş Sözleşme hükümlerini kabul etmiş sayıldığını ve Sözleşmede belirtilen ödeme şekline uygun ödemeyi yapacağını kabul ve taahhüt eder.
7.4.Alıcı, …………………….  internet sitesinden Satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri , satışa konu Ürünün temel nitelikleri, vergiler dahil olmak üzere satış fiyatı, ödeme sekli, teslimat koşulları ve masrafları dahil satışa konu Ürün ile ilgili tüm ön bilgiler, "cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve itirazlarını iletebilecekleri resmi makamlar dahil ilgili kurumlar hususlarında açık, anlaşılır ve internet ortamına uygun şekilde bilgi sahibi olduğunu bu ön bilgileri elektronik ortamda teyit ettiğini kabul ve beyan eder.
7.5. Bir önceki maddeye bağlı olarak Alıcı, Ürün sipariş ve ödeme koşullarının, Ürün kullanım ……………………. talimatlarının, olası durumlara karşı alınan tedbirlerin ve yapılan uyarıların olduğu sipariş/ödeme/kullanım prosedürü bilgilerini okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.
7.6. Alıcı, aldığı Ürünü iade etmek istemesi durumunda ne surette olursa olsun Ürüne ve ambalajına zarar vermemeyi, iade anında fatura aslını ve irsaliyesini iade etmeyi kabul, beyan ve taahhüt eder.
Madde 8 – Sipariş/Ödeme Prosedürü
Sipariş:
Alışveriş sepetine eklenen ürünlerin KDV dahil USD tutarı (Taksitli işlemlerde toplam taksit tutarları) Alıcı tarafından onaylandıktan sonra, ilgili banka kartının posu üzerinden işleme alınır. Bu nedenle siparişler, sevk edilmeden önce müşteriye sipariş onay maili gönderilir. Sipariş onay maili gönderilmeden sevkiyat yapılmaz.
Süreçteki herhangi bir aksama durumu ya da kredi kartı ile ilgili ortaya çıkabilecek problemler Alıcıya Sözleşmede belirttiği telefon/faks/e-mail yollarından biri veya bir kaçı kullanılmak sureti ile bildirilir. Gerekirse Alıcıdan bankası ile görüşmesi istenebilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’ nin) Satıcı hesaplarına ulaştığının belirlendiği andır.
İstisnai olarak haklı bir nedenle sözleşme konusu malın tedarik edilemeyeceğinin anlaşılması ve/veya stok problemi ile karşılaşılması durumunda alıcı hemen açık ve anlaşılır bir şekilde bilgilendirilip onay vermesi durumunda Alıcıya eşit kalitede ve fiyatta başka bir ürün gönderilebilir ya da Alıcının arzusu ve seçimi doğrultusunda; yeni başka bir ürün gönderilebilir, Ürünün stoklara girmesi ya da teslime engel diğer engelin ortadan kalkması beklenebilir ve/veya sipariş iptal edilebilir.
Sözleşme konusu Ürünün teslim yükümlülüğünün yerine getirilmesinin imkânsızlaştığı hâllerde Alıcı bu durumdan haberdar edilerek ödemiş olduğu toplam bedel ve varsa onu borç altına sokan her türlü belge en geç on gün içinde kendisine iade edilerek Sözleşme iptal edilir. Böyle bir durumda Alıcının Satıcıdan ilave herhangi bir maddi ve manevi zarar talebi olmayacaktır.
Ödeme:
…………………….  adresinde, internet ortamında kredi kartı bilgilerini kullanmak istemeyen Alıcılara nakit havale ile sipariş imkanları sunulmuştur. Havale ile ödemede Alıcı kendisine en uygun bankayı seçip havalesini yapabilir. Eğer EFT yapılmışsa hesaba geçme tarihi dikkate alınacaktır. Havale ve/veya EFT yaparken "Gönderen Bilgileri”nin fatura bilgileri ile aynı olması ve sipariş numarasının yazılması gereklidir.
Ürünün tesliminden sonra Alıcıya ait kredi kartının Alıcının kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşun ürün bedelini Satıcıya ödememesi halinde, Alıcının kendisine teslim edilmiş ürünü 10 (on) gün içinde Satıcıya göndermesi zorunludur. Bu tür durumlarda nakliye giderleri Alıcıya aittir.
Alıcı kredi kartı ile ödeme yapmayı tercih etmiş ise Alıcı, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin banka ve Alıcı arasındaki "Kredi Kartı Sözleşmesi” kapsamında uygulanacağını kabul, beyan ve taahhüt eder.
Madde 9 – Sevkiyat/Teslimat Prosedürü
Sevkiyat:
Sipariş onayı mailinin gönderilmesiyle birlikte, Ürün Satıcının anlaşmalı olduğu kargo şirketine verilir.
Teslimat:
Ürün/ürünler satıcının anlaşmalı olduğu kargo ile Alıcının adresine teslim edilecektir. Teslimat süresi, sipariş onayı mailinin gönderilmesinden ve sözleşmenin kurulmasından itibaren 30 gündür. Alıcıya önceden yazılı olarak veya bir sürekli veri taşıyıcısıyla bildirilmek koşuluyla bu süre en fazla on gün uzatılabilir.
Ürünler, kargo şirketlerinin adres teslimatı yapmadığı bölgelere telefon ihbarlı olarak gönderilir.
Kargo şirketinin haftada bir gün teslimat yaptığı bölgelerde, sevk bilgilerindeki yanlışlık ve eksiklik olduğu hallerde, bazı sosyal olaylar ve doğal afetler gibi durumlarda belirtilen gün süresinde sarkma olabilir. Bu sarkmalardan dolayı alıcı satıcıya herhangi bir sorumluluk yükleyemez. Ürün, Alıcıdan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden, sevk bilgilerindeki yanlışlık ve/veya Alıcının yerinde olmamasından doğabilecek ekstra kargo bedelleri de dahil olmak üzere zararlardan ve giderlerden Satıcı sorumlu değildir. Belirtilen günler içeriğinde ürün/ürünler müşteriye ulaşmadıysa teslimat problemleri müşteri hizmetlerine ……….@……..com e-mail adresi kullanılmak sureti ile derhal bildirilmelidir.
Zarar görmüş paket durumunda; zarar görmüş paketler teslim alınmayarak kargo şirketi yetkilisine tutanak tutturulmalıdır. Eğer kargo şirketi yetkilisi paketin hasarlı olmadığı görüşünde ise, paketin orada açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla tespit edilmesini isteme hakkı alıcıda vardır. Paket Alıcı tarafından teslim alındıktan sonra kargo şirketinin görevini tam olarak yaptığı kabul edilmiş olur. Paket kabul edilmemiş ve tutanak tutulmuş ise, durum, tutanağın Alıcıda kalan kopyasıyla birlikte en kısa zamanda Satıcı müşteri hizmetlerine bildirilmelidir.
Madde 10 – Ürün İade ve Cayma Hakkına İlişkin Prosedürü
10.1. Alıcı tacir ise 8 (sekiz), Alıcı tüketici ise 14 (on dört) gün olmak üzere; Ürünü teslim aldıktan sonra belirtilen sürede sevk edildiği şekil, şart, nitelik ve pakette olmak ön şartıyla herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin Sözleşmeden cayma hakkına sahiptir. 385 sayılı Vergi Usul Kanunu Genel Tebliği uyarınca iade işlemlerinin yapılabilmesi için Alıcının mal ile birlikte teslim edilen Satıcıya ait 2 adet faturanın alt kısmındaki iade bölümlerini eksiksiz ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını ürün ile birlikte Satıcıya göndermesi diğer nüshasını da uhdesinde tutması gerekmektedir. Cayma hakkı süresi Alıcıya Ürünün teslim edildiği günden itibaren başlar. İade edilen Ürünün geri gönderim bedeli Alıcı tarafından karşılanmalıdır.
10.2. Alıcının istekleri ve/veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan mallar için cayma hakkı söz konusu değildir.
10.3. Alıcının cayma hakkını kullanması halinde Satıcı, cayma bildirimini içeren faturanın Ürünle birlikte kendisine ulaşmasından itibaren en geç on gün içerisinde almış olduğu toplam bedeli ve Alıcı tüketici ise tüketiciyi borç altına sokan her türlü belgeyi tüketiciye hiçbir masraf yüklemeden iade edecektir.
10.4. Ambalajı açılmış, kullanılmış, tahrip edilmiş vesaire şekildeki ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır. Sehven alınan Üründe ve/veya Ürünün ambalajında herhangi bir açılma, bozulma, kırılma, tahrip, yırtılma, kullanılma ve sair durumlar tespit edildiği hallerde ve Ürünün Alıcıya teslim edildiği andaki hali ile iade edilememesi durumunda Ürün iade alınmaz ve bedeli iade edilmez.
10.5. Ürün iadesi için, durum öncelikli olarak müşteri hizmetlerine iletilmelidir. Ürünün iade olarak gönderilme bilgisi, Satıcı tarafından Alıcıya iletilir. Bu görüşmeden sonra Ürün iade ile ilgili bilgileri içeren fatura ile birlikte Alıcı adresine teslimatı yapan kargo şirketi kanalıyla Satıcıya ulaştırmalıdır. Satıcıya ulaşan iade Ürün iş bu sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul edilir, geri ödemesi de Alıcı kredi kartına/hesabına yapılır. Ürün iade edilmeden bedel iadesi yapılmaz. Kredi kartına yapılan iadelerin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır.
10.6. Alışveriş kredi kartı ile ve taksitli olarak yapılmışsa, kredi kartına iade prosedürü şu şekilde uygulanacaktır: Alıcı Ürünü kaç taksit ile satın alma talebini iletmiş ise, Banka Alıcıya geri ödemesini taksitle yapmaktadır. Satıcı, bankaya Ürün bedelinin tamamını tek seferde ödedikten sonra, banka poslarından yapılan taksitli harcamaların Alıcının kredi kartına iadesi durumunda konuya müdahil Tarafların mağdur duruma düşmemesi için talep edilen iade tutarları, yine taksitli olarak hamil taraf hesaplarına banka tarafından aktarılır. Alıcının satış iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihleri çakışmazsa her ay karta 1 (bir) iade yansıyacak ve Alıcı iade öncesinde ödemiş olduğu taksitleri satışın taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksit sayısı kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.
10.7. Kart ile alınmış Ürünün iadesi durumunda Satıcı, banka ile yapmış olduğu sözleşme gereği Alıcıya nakit para ile ödeme yapamaz. Üye işyeri yani Satıcı, bir iade işlemi söz konusu olduğunda ilgili yazılım aracılığı ile iadesini yapacak olup, üye işyeri yani Satıcı ilgili tutarı bankaya nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda detayları belirtilen prosedür gereğince Alıcıya nakit olarak ödeme yapılamamaktadır. Kredi kartına iade, Alıcının bankaya bedeli tek seferde ödemesinden sonra, banka tarafından yukarıdaki prosedür gereğince yapılacaktır.
Madde 11 – Garanti
Ticari nitelikteki işler için 6102 sayılı Türk Ticaret Kanunu hükümleri geçerli olacaktır.Madde 12 – Gizlilik
Alıcı tarafından işbu Sözleşmede belirtilen bilgiler ile ödeme yapmak amacı ile Satıcıya bildirdiği bilgiler Satıcı tarafından 3. şahıslarla paylaşılmayacaktır. Satıcı bu bilgileri sadece idari/yasal zorunluluğun mevcudiyeti çerçevesinde açıklayabilecektir. Araştırma ehliyeti belgelenmiş her türlü adli soruşturma dahilinde satıcı kendisinden istenen bilgiyi elinde bulunduruyorsa ilgili makama sağlayabilir.
Kredi Kartı bilgileri kesinlikle saklanmaz, kredi kartı bilgileri sadece tahsilat işlemi sırasında ilgili bankalara güvenli bir şekilde iletilerek provizyon alınması için kullanılır ve provizyon sonrası sistemden silinir. Alıcıya ait e-posta adresi, posta adresi ve telefon gibi bilgiler yalnızca satıcı tarafından standart ürün teslim ve bilgilendirme prosedürleri için kullanılır. Bazı dönemlerde kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon bilgileri Alıcıya onayı sonrasında gönderilebilir.
Madde 13 – Kişisel Verilerin Korunması ve Fikri-Sınai Haklar
13.1. Satıcı, işbu Sözleşme kapsamındaki kişisel verileri sadece Ürünün sunulması amacıyla sınırlı olarak ve 6698 sayılı Kişisel Verilerin Korunması Kanunu’na, (“KVKK”) ikincil mevzuata ve Kişisel Verileri Koruma Kurulu kararlarına uygun olarak işleyecektir. Satıcı, ……………… internet sitesi üzerinden eriştiği kişisel veriler haricinde Alıcının kişisel verilerini işlemeyeceğini ve belirtilen internet süresi üzerinden sağlanan yöntemler dışında Alıcı ile harici olarak iletişime geçmeyeceğini kabul, beyan ve taahhüt eder.
13.2. Alıcı, işbu Sözleşme kapsamında sağladığı kişisel verilerin doğru, eksiksiz ve güncel olduğunu kontrol etmekle, bu bilgileri üçüncü kişilerle paylaşmamak, ilgisiz kişilerce erişilememesi için virüs ve benzeri zararlı uygulamalara ilişkin olanlar dahil gerekli tedbirleri almak ve söz konusu kişisel verilerin güvenliğini sağlamakla yükümlü olduğunu, aksi halde doğacak zararlardan ve üçüncü kişilerden gelen taleplerden bizzat sorumlu olduğunu kabul, beyan ve taahhüt eder.
13.3. ……………… internet sitesine ait her türlü bilgi ve içerik ile bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda; Satıcının anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm fikri-sınai haklar ve mülkiyet hakları Satıcıya aittir.
Madde 15 – Bildirimler ve Delil Sözleşmesi
15.1. Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında, yazılı olarak yapılacaktır.
15.2. Alıcı, Sözleşmeden doğabilecek ihtilaflarda Satıcının ticari defter, bilgisayar, kayıt ve diğer belgelerinin bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
Madde 16- Uyuşmazlık Durumunda Yetkili Mahkeme ve İcra Daireleri
İşbu sözleşmenin uygulanmasından kaynaklanan uyuşmazlık halinde,
Alıcı tacir ise İstanbul Merkez Adliye Mahkeme ve İcra Daireleri,
Alıcı tüketici ise; Sanayi ve Ticaret Bakanlığınca her yılın aralık ayında ilan edilen değere kadar Tüketici Hakem Heyetleri ile Alıcının veya Satıcının yerleşim yerindeki Tüketici Mahkemeleri yetkilidir.
Madde 17 – Yürürlük
17 (on yedi) maddeden ibaret Sözleşme, Taraflarca okunarak, madde 4’te belirtilen sipariş tarihinde, Alıcı tarafından elektronik ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir. 
TÜKETİCİ HAKLARI – CAYMA – İPTAL İADE KOŞULLARI
Alıcı tacir ise 8 (sekiz), tüketici ise 14 (on dört) gün olmak üzere; ürünü teslim aldıktan sonra belirtilen sürede sevk edildiği şekil, şart, nitelik ve pakette olmak ön şartıyla herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına sahiptir. 385 sayılı Vergi Usul Kanunu Genel Tebliği uyarınca iade işlemlerinin yapılabilmesi için alıcının mal ile birlikte teslim edilen satıcıya ait 2 adet faturanın alt kısmındaki iade bölümlerini eksiksiz ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını ürün ile birlikte satıcıya göndermesi diğer nüshasını da uhdesinde tutması gerekmektedir. Cayma hakkı süresi alıcıya malın teslim edildiği günden itibaren başlar. İade edilen ürün veya ürünlerin geri gönderim bedeli alıcı tarafından karşılanmalıdır.
Alıcının istekleri ve/veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan ürünler için cayma hakkı söz konusu değildir.
Alıcının cayma hakkını kullanması halinde satıcı, cayma bildirimini içeren faturanın ürünle birlikte kendisine ulaşmasından itibaren en geç on gün içerisinde almış olduğu toplam bedeli ve varsa tüketiciyi borç altına sokan her türlü belgeyi tüketiciye hiçbir masraf yüklemeden iade edecektir.
Ambalajı açılmış, kullanılmış, tahrip edilmiş vesaire şekildeki ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır.
Sehven alınan üründe ve ambalajında herhangi bir açılma, bozulma, kırılma, tahrip, yırtılma, kullanılma ve sair durumlar tespit edildiği hallerde ve ürünün alıcıya teslim edildiği andaki hali ile iade edilememesi durumunda ürün iade alınmaz ve bedeli iade edilmez.
Ürün iadesi için, durum öncelikli olarak müşteri hizmetlerine iletilmelidir.
Ürünün iade olarak gönderilme bilgisi, satıcı tarafından müşteriye iletilir. Bu görüşmeden sonra ürün iade ile ilgili bilgileri içeren fatura ile birlikte alıcı adresine teslimatı yapan Kargo şirketi kanalıyla satıcıya ulaştırmalıdır.  
İade ve değişim işlemlerinde anlaşmalı olduğumuz kargo şirketi ……. Kargo ile "Karşı Ödemeli” olarak gönderebilirsiniz. …… Kargo şirketi haricinde gelen "Karşı Ödemeli” gönderilerde, gönderim ücreti tarafınızdan ödenmektedir.
İade gönderilerinizde iade kargo kodu olarak mutlaka ……….. numarasını giriniz. Bu sayede kargonuz hızlıca ve sorunsuz olarak elimize ulaşacaktır.
Satıcıya ulaşan iade ürün iş bu sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul edilir, geri ödemesi de alıcı kredi kartına/hesabına yapılır. Ürün iade edilmeden bedel iadesi yapılmaz. Kredi kartına yapılan iadelerin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır.
Alışveriş kredi kartı ile ve taksitli olarak yapılmışsa, kredi kartına iade prosedürü şu şekilde uygulanacaktır: Alıcı ürünü kaç taksit ile satın alma talebini iletmiş ise, Banka alıcıya geri ödemesini taksitle yapmaktadır. Satıcı, bankaya ürün bedelinin tamamını tek seferde ödedikten sonra, banka poslarından yapılan taksitli harcamaların alıcının kredi kartına iadesi durumunda konuya müdahil tarafların mağdur duruma düşmemesi için talep edilen iade tutarları, yine taksitli olarak hamil taraf hesaplarına Banka tarafından aktarılır.
Alıcının satış iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihleri çakışmazsa her ay karta 1(bir) iade yansıyacak ve alıcı iade öncesinde ödemiş olduğu taksitleri satışın taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksit sayısı kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.
Kart ile alınmış mal ve hizmetin iadesi durumunda satıcı, Banka ile yapmış olduğu sözleşme gereği alıcıya nakit para ile ödeme yapamaz.
Üye işyeri yani satıcı, bir iade işlemi söz konusu olduğunda ilgili yazılım aracılığı ile iadesini yapacak olup, üye işyeri yani satıcı ilgili tutarı Bankaya nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda detayları belirtilen prosedür gereğince alıcıya nakit olarak ödeme yapılamamaktadır. Kredi kartına iade, alıcının Bankaya bedeli tek seferde ödemesinden sonra, Banka tarafından yukarıdaki prosedür gereğince yapılacaktır.

`;

export default ({ headingText = "Şartlar ve Koşullar", content = defaultContent }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <TextContainer dangerouslySetInnerHTML={{ __html: content }} />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

