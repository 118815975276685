import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
//import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import reviewImage from "images/Respond-to-Google-Reviews.png";
// import nfcImage from "images/nfc.png";


const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap justify-center bg-gray-200 px-4 py-2 rounded leading-none mt-12 xl:mt-0 gap-2`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 text-center text-sm sm:text-base font-medium rounded transition duration-300 w-auto`}

  ${props => props.active 
    ? tw`bg-primary-500 text-white shadow-lg` /* Aktif tab için stil */
    : tw`bg-gray-200 text-gray-600`} /* Pasif tab için stil */
`;



const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-contain relative rounded-t`}
  background-repeat: no-repeat;
  background-color: black;
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

//const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Hizmetlerimizi İnceleyin",
  tabs = {
    "NFC Kartları": [
      {
        imageSrc:
          "/feedup_nfc2.jpg",
        title: "Tekli NFC Kart",
        content: "Google yorumları toplamak için kullanılır.",
        price: "₺399",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "/feedup_nfc2.jpg",
        title: "2'li NFC Kart Paketi",
        content: "Tek çalışanlı küçük işletmeler için uygun",
        price: "₺699",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "/feedup_nfc2.jpg",
        title: "3'lü NFC Kart Paketi",
        content: "Tek çalışanlı küçük işletmeler için uygun",
        price: "₺949",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "/feedup_nfc2.jpg",
        title: "5'li NFC Kart Paketi",
        content: "Orta ölçekli işletmelere yönelik.",
        price: "₺1399",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "/feedup_nfc2.jpg",
        title: "10'lu NFC Kart Paketi",
        content: "Büyük işletmeler için ideal.",
        price: "₺2499",
        url: "https://app.feedup.ai/dashboard"
      }
    ],
    "Yapay Zeka Yanıtları": [
      {
        imageSrc:
          reviewImage,
        title: "100 Yorum Yanıtı Paketi",
        content: "Google yorumlarına yapay zeka ile hızlı yanıtlar üretin.",
        price: "₺200",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          reviewImage,
        title: "500 Yorum Yanıtı Paketi",
        content: "Daha fazla yorum için ekonomik çözüm.",
        price: "₺800",
        url: "https://app.feedup.ai/dashboard"
      }
    ]/* ,
    "Paketler": [
      {
        imageSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp4OuhEBSEhbuPsQv48vPBsmaRhtOQ1MRv-w&s",
        title: "Basic Paket/Aylık Ödeme",
        content: "3 NFC Kart, 100 Yanıt, Haftalık Rapor.",
        price: "650",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp4OuhEBSEhbuPsQv48vPBsmaRhtOQ1MRv-w&s",
        title: "Basic Paket/Yıllık Ödeme",
        content: "3 NFC Kart, 100 Yanıt, Haftalık Rapor.",
        price: "6500",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp4OuhEBSEhbuPsQv48vPBsmaRhtOQ1MRv-w&s",
        title: "Pro Paket//Aylık Ödeme",
        content: "10 NFC Kart, 500 Yanıt, Haftalık ve Aylık Rapor.",
        price: "999",
        url: "https://app.feedup.ai/dashboard"
      },
      {
        imageSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp4OuhEBSEhbuPsQv48vPBsmaRhtOQ1MRv-w&s",
        title: "Pro Paket/Yıllık Ödeme",
        content: "10 NFC Kart, 500 Yanıt, Haftalık ve Aylık Rapor.",
        price: "9990",
        url: "https://app.feedup.ai/dashboard"
      }
    ] */
  }
})  => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} target="_blank" rel="noopener noreferrer" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Satın Al</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
