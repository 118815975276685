import React, { useRef } from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
// import { FaCreditCard, FaRobot, FaChartBar } from "react-icons/fa";
//import reviewImage from "images/Respond-to-Google-Reviews.png";
import reviewImage from "images/feedup_nfc2.png";



export default () => {
  const productsRef = useRef(null);

  const scrollToProducts = () => {
    if (productsRef.current) {
      console.log("Scrolling to products...");
      productsRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("productsRef is not attached to any DOM element.");
    }
  };

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  //const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Satışları Artıran <HighlightedText>NFC Yorum Kartları</HighlightedText></>}
        description="NFC kartlarla müşterilerinizin Google'da sizi kolayca değerlendirmesini sağlayın ve geri bildirimlerinizi artırın!"
        imageSrc="https://www.truzzer.com/wp-content/uploads/2023/08/google-review-nfc-business-card-tap.jpg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Paketleri Görüntüle"
        primaryButtonAction={scrollToProducts}
        watchVideoButtonText="Nasıl Çalışır?"
        watchVideoYoutubeUrl=""
      />
      <MainFeature
        subheading={<Subheading>Yenilikçi Çözümler</Subheading>}
        heading={
          <>
            İşletmeniz için <HighlightedText>özelleştirilmiş çözümler</HighlightedText>
          </>
        }
        description={
          <Description>
            Yorumlara hızlı ve profesyonel cevaplar vererek müşteri memnuniyetini artırın. Size özel hazırlanan analiz raporlarıyla işletmenizi bir adım öne taşıyın!
            <br />
            <br />
            Paket seçeneklerimizle ihtiyacınıza uygun çözümler sunuyoruz.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Paketleri Görüntüle"
        primaryButtonAction={scrollToProducts}
        imageSrc={reviewImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <div ref={productsRef}>
        <TabGrid
          heading={
            <>
              Öne Çıkan <HighlightedText>Hizmetlerimiz.</HighlightedText>
            </>
          }
        />
      </div>
      <Features
        heading={
          <>
            Sunduğumuz <HighlightedText>Hizmetler.</HighlightedText>
          </>
        }
        linkText= "Daha fazlası"
        cards={[
          {
            imageSrc: "https://cdn-icons-png.flaticon.com/256/3252/3252741.png",
            title: "NFC Yorum Kartları",
            
            description: "Tekli, 3'lü, 5'li veya 10'lu setler halinde NFC kartları satın alın.",
            url: "/features"
          },
          {
            imageSrc: "https://png.pngtree.com/png-clipart/20230504/original/pngtree-response-line-icon-png-image_9139028.png",
            title: "Yapay Zeka Destekli Yanıtlar",
            description: "Google yorumlarına otomatik ve profesyonel yanıtlar üretin.",
            url: "/features"
          },
          {
            imageSrc: "https://static.thenounproject.com/png/2766140-200.png",
            title: "Haftalık ve Aylık Raporlar",
            description: "Yorumlarınızı detaylı analizlerle güçlendirin.",
            url: "/features"
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>Güvenilir Hizmet</Subheading>}
        heading={<>Neden <HighlightedText>Bizi Tercih Etmelisiniz?</HighlightedText></>}
        statistics={[
          {
            key: "NFC kartlarla etkileşim, yapay zeka ile fark yaratın",
            value: "Kolay Çözüm, Büyük Etki",
          },
          {
            key: "Müşteri geri bildirimlerini avantaja çevirin",
            value: "Yorumlarınızla Güçlenin"
          },
          {
            key: "Yenilikçi teknolojilerle bir adım önde olun",
            value: "İşletmeniz İçin Akıllı Destek"
          }
        ]}
        description="Müşteri yorumlarını toplamak, analiz etmek ve yapay zeka destekli profesyonel yanıtlar üretmek için yenilikçi çözümler sunuyoruz. İşletmenizin ihtiyaçlarına özel esnek paketlerimizle zaman kazandırırken, müşteri memnuniyetini artırıyoruz. Güçlü teknolojimiz ve uzman ekibimizle işletmenizi bir adım öteye taşıyoruz."
        primaryButtonText="Paketleri Keşfet"
        primaryButtonUrl="/pricing"
        imageInsideDiv={false}
        imageSrc="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*H8_qskGMer4C5bP6d9OvNA.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Müşterilerimiz <HighlightedText>Bizi Seviyor.</HighlightedText></>}
      />

      <Footer />
    </AnimationRevealPage>
  );
};
//      <DownloadApp
// text={<>İşletmenizi büyütmek için <HighlightedTextInverse>hemen başlayın.</HighlightedTextInverse></>}
// />