import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-wrap justify-center items-center max-w-screen-lg mx-auto py-20 md:py-24`}
  
  ${({ cardsLength }) =>
    cardsLength === 1 &&
    css`
      ${tw`flex-col justify-center items-center h-full`}
    `}
`;


const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ 
  cards = null, 
  heading = "Ofisimiz", 
  subheading = "Konumlar", 
  description = "İhtiyacınız olan her şey için bizimle iletişime geçebilirsiniz. Size her zaman destek olmaktan mutluluk duyarız." 
}) => {
  /*
   * Bu bileşen, aşağıda tanımlanan kartları temsil eden bir nesne dizisine sahiptir. Her nesne aşağıdaki anahtarları içerebilir (ihtiyacınıza göre özelleştirebilirsiniz, bu bileşene daha fazla kart eklemek için nesne sayısını artırabilirsiniz):
   *  1) title - kartın başlığı
   *  2) description - kartın açıklaması
   *  Eğer belirli bir kart için bir anahtar sağlanmazsa, varsayılan bir değer kullanılır.
   */

  const defaultCards = [
    {
      title: "Güvenli",
      description: "Sadece en üst düzey güvenlik sağlayan satıcılarla çalışıyoruz."
    },
    { 
      title: "7/24 Destek",
      description: "İhtiyacınız olan her an size destek olmak için buradayız."
    },
    { 
      title: "Güvenilir",
      description: "Hizmetlerimiz her zaman tutarlı ve güvenilir."
    },
    { 
      title: "Kolay",
      description: "Kullanıcı dostu çözümlerle hayatınızı kolaylaştırıyoruz."
    },
    { 
      title: "Özelleştirilebilir",
      description: "İhtiyaçlarınıza göre tamamen özelleştirilebilir çözümler sunuyoruz."
    },
    { 
      title: "Hızlı",
      description: "Hizmetlerimiz hız ve verimlilik üzerine odaklanmıştır."
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer cardsLength={cards.length}>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer" css={tw`text-center flex flex-col items-center`}>
                <span className="title">{card.title || "Tamamen Güvenli"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>

        ))}
      </ThreeColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};
